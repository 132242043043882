.mui-form{
    padding: 50px;
    background-color:white;
}

.css-1qua55p{
    padding-top:30px !important;
}

.service-grid-space{
    margin-top:23px;
}

.title{
    font-weight:bold !important
}
.dark-gray{
    color: #867C7F;
}
.light-gray{
    color: #A6AEBD;
}

.deliver_address_textfield{
    margin:0 0 0 10px !important
}
.css-12ajs1c-MuiDataGrid-root.MuiDataGrid-autoHeight,
.css-krfx2n-MuiDataGrid-root.MuiDataGrid-autoHeight,
.css-1j5vxlr.MuiDataGrid-autoHeight,
.css-1i7atw7.MuiDataGrid-autoHeight{
    border-style: none !important;
    border-color: none !important;
    border-width: none !important;
    border-radius: none !important;
}