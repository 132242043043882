.AeronetDataGrid-cell--pointer {
    cursor: pointer !important;
}

.AeronetDataGrid-cell--cursor-default {
    cursor: default !important;
}

.css-trx1j8-MuiFormLabel-root-MuiInputLabel-root, .css-rezmua{
    left: 15px !important;
    top: -5px !important;
}

.css-2rjk8p-MuiFormControl-root-MuiDataGrid-filterFormValueInput , .css-1wxw1ev{
    margin-bottom: 0 !important;
}